import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { InternshipListComponent } from './internship-list.component';
import { InernshipListRoutingModule } from './internship-list.routing';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { ProcessingModule } from '../processing/processing.module';
import { TableModule } from 'primeng/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  imports: [
    SharedModule,
    InernshipListRoutingModule,
    ErrorHandlerModule,
    ProcessingModule,
    TableModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  declarations: [InternshipListComponent],
  providers: [],
  exports: [InternshipListComponent],
})
export class InternshipListModule {}
