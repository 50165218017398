export const GlobalSettings = {
  numberOfRetries: 0,
  alertDuration: -1,
  pageSize: 7,
  routes: {
    gradeDetail: 'grade/detail/:id',
    internshipList: 'internships',
    internshipOverview: 'internships/overview/:id',
    internshipDetail: 'internships/detail/:id',
    internshipDetailReport: 'internships/report/:id',
    login: 'login',
    forgotten: 'forgotten',
    changePassword: 'changepassword',
  },
  navigate: {
    gradeDetail: 'grade/detail/',
    internshipList: 'internships',
    internshipOverview: 'internships/overview/',
    internshipDetail: 'internships/detail/',
    internshipDetailReport: 'internships/report/',
    login: 'auth/login',
    forgotten: '/auth/forgotten',
    changePassword: '/auth/changepassword',
    notAllowed: '/not-allowed',
  },
};
