import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Table, TableCheckbox, TableLazyLoadEvent } from 'primeng/table';
import { TrainerService } from '../services/trainer.service';
import { Trainer } from '../models/trainer';
import { ErrorDialogComponent } from '../../layout/shared/error-dialog/error-dialog.component';
import { GridRequest } from '../models/grid-request';
import { GlobalSettings } from '../../shared/global-settings';

@Component({
  selector: 'app-trainers',
  templateUrl: '../trainers/trainers.component.html',
  styleUrls: ['../trainers/trainers.component.css'],
  providers: [TrainerService],
})
export class TrainerSelectDialogComponent {
  public organization: string;

  trainers: Trainer[];
  totalRecords: number;
  rowsSelectionTrainers$: Observable<number>;
  selectedTrainers: Trainer[];
  @ViewChild('dt') table: Table;
  selectAllChecked = false;
  cols: any[];
  gridRequest: GridRequest;
  public pageSize = 10;

  constructor(
    private trainerService: TrainerService,
    private dialogRef: MatDialogRef<TrainerSelectDialogComponent>,
    private dialog: MatDialog
  ) {}
  isUpdating: boolean;

  filter(event: TableLazyLoadEvent) {}

  loadTrainers(event: TableLazyLoadEvent) {
    this.isUpdating = true;

    let gridRequest: GridRequest = this.createEmptyGridRequest();
    gridRequest.offset = event.first;
    gridRequest.sortfield = event.sortField as string;
    gridRequest.sortAsc = event.sortOrder === 1;
    gridRequest.filters = event.filters;
    gridRequest.filterOrSortChanged = true;
    this.gridRequest = gridRequest;

    //console.log(event);
    setTimeout(() => {
      this.trainerService
        .getList(this.gridRequest)
        .then((data) => {
          this.trainers = data['records'];
          this.totalRecords = data['count'];

          this.isUpdating = false;
        })
        .catch((e) => {
          this.isUpdating = false;
          console.error(e);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: 'Praxisausbildende konnten nicht geladen werden.',
            },
          });
        });
    }, 1000);
  }

  selectTrainer(trainer: Trainer) {
    this.dialogRef.close(trainer);
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any): void {}

  getBriefAnerkennung(): void {}

  selectAll(): void {}

  downloadExport(): void {}

  createEmptyGridRequest(): GridRequest {
    let gridRequest = new GridRequest();
    gridRequest.pageSize = GlobalSettings.pageSize;
    gridRequest.withArchive = false;
    gridRequest.offset = 0;
    gridRequest.sortAsc = true;
    return gridRequest;
  }
}
