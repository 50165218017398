<h2 mat-dialog-title>Informationen zum neuen Login</h2>
<mat-dialog-content>
  <p>
    Aufgrund neuer IT-Security Richtlinien musste für die Praxisapplikationen
    die Multi-Faktor-Authentifizierung (MFA) eingeführt werden. Die Umstellung
    auf Anmeldung via MFA erfolgt am
    <b>Mittwoch, 07.08.2024 um ca. 10: 00 Uhr</b>.
  </p>
  <p>
    <b>Was ändert sich bei der Anmeldung?</b> Neu gibt es zwei
    Anmeldemöglichkeiten am Praxismarkt und Praxisbeurteilung. Die Anmeldung
    kann entweder über ein Microsoft Konto oder über die Switch Edu-ID erfolgen.
    Das jeweilige Konto muss zwingend auf die E-Mail-Adresse lauten, mit welcher
    Sie sich aktuell an der jeweiligen Applikation anmelden. Falls dem nicht so
    ist, beachten Sie bitte den Hinweis «was tun, wenn bereits ein Konto
    besteht, jedoch auf eine andere E-Mail-Adresse lautet» (ACHTUNG:
    unpersönliche E-Mail-Adressen können nicht mehr verwendet werden). Weiter
    benötigen Sie ein persönliches Mobiltelefon mit einer entsprechenden
    Authenticator App für die 2- Faktor Authentifizierung (<a
      href="https://support.microsoft.com/de-de/account-billing/einrichten-der-microsoft-authenticator-app-als-%C3%BCberpr%C3%BCfungsmethode-33452159-6af9-438f-8f82-63ce94cf3d29"
      target="_blank"
      >Einrichten der Microsoft Authenticator App</a
    >).
  </p>
  <p>
    <b
      >Was tun, wenn bereits ein Konto bei Microsoft oder Switch Edu-ID besteht,
      das jedoch auf eine andere E-Mail Adresse lautet?</b
    >
    Im Falle eines Microsoft Kontos können Sie uns diese E-Mail-Adresse via
    servicedesk@zhaw.ch mitteilen. Bei der Switch Edu-ID können Sie wie folgt
    vorgehen. Melden Sie sich unter
    <a href="https://eduid.ch/" target="_blank"> https://eduid.ch/ </a>
    mit Ihrem Konto an und fügen Sie die E-Mail-Adresse, die Sie bisher für das
    Login beim Praxismarkt / Praxisbeurteilung genutzt haben unter "Profil
    &rarr; E-Mail-Adressen &rarr; E- Mail Adressen verwalten" hinzu
  </p>
  <p>
    <b
      >Was müssen Sie tun, wenn Sie noch kein Microsoft Konto oder Switch Edu-ID
      haben?</b
    >
    Um ein neues Konto zu erstellen, können Sie wie folgt vorgehen:
  </p>
  <ul>
    <li>
      Microsoft Konto:
      <a
        href="https://support.microsoft.com/de-de/account-billing/erstellen-eines-neuen-microsoft-kontos-a84675c3-3e9e-17cf-2911-3d56b15c0aaf"
        target="_blank"
      >
        Erstellen eines neuen Microsoft-Kontos
      </a>
      &rarr; Hier finden Sie alle Informationen für die Erstellung eines
      Microsoft Kontos.
    </li>
    <li>
      Switch Edu-ID:
      <a href="https://eduid.ch/registration" target="_blank">
        Registration Switch Edu-ID
      </a>
      &rarr; Hier können Sie ein neues Switch Edu-ID Konto eröffnen.
    </li>
  </ul>
  <p>
    Falls Fragen oder Probleme bei der Anmeldung auftreten, dürfen Sie sich
    gerne an den ZHAW Servicedesk wenden (<a href="mailto: servicedesk@zhaw.ch">
      servicedesk@zhaw.ch </a
    >)
  </p>
  <p>
    Wir danken Ihnen für Ihr Verständnis und Ihre Mitarbeit bei der Umsetzung
    dieser wichtigen Sicherheitsmassnahme.
  </p></mat-dialog-content
>
