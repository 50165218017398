import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { InternshipDetail } from '../models/internship-detail';
import { InternshipService } from '../services/internship.service';
import { InternshipDetailFormErrorMessages } from './internship-detail-form-error-messages';
import { GlobalSettings } from '../../shared/global-settings';
import { UserGroup } from '../models/usergroup';
import { HeaderComponent } from '../../layout/header/header.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GradeService } from '../services/grade.service';
import { GradingScaleElement } from '../models/grading-scale-element';

@Component({
  selector: 'app-internship-detail-form',
  templateUrl: './internship-detail-form.component.html',
  styleUrls: ['./internship-detail-form.component.css'],
})
export class InternshipDetailFormComponent implements OnInit {
  item: InternshipDetail;
  errors: { [key: string]: string } = {};
  isUpdating = false;
  infoWorkingHours: string;
  infoRemarks: string;
  infoAbsences: string;
  infoFurtherLerning: string;
  error: Error;
  fatalError: boolean = false;
  usergroups: UserGroup = null;
  isStudent = true;
  deptString: string;
  zmcNotenskala: GradingScaleElement[];
  zmcNotenskalaDataSourceObjectId = 'D4AA6092-B127-4549-BD7B-03F82297C10E';
  isDiskreditierungRight = true;
  qualifikationDataSourceObjectId = '0548FC6C-0C8B-499F-8098-0C3A894F4C44';
  isQualifikationState: boolean;
  itemReadonlyForIHG: boolean;
  isPm1OrPm2 = false;
  isPm3 = false;

  constructor(
    private internshipService: InternshipService,
    private route: ActivatedRoute,
    private router: Router,
    private gradeService: GradeService
  ) {}

  ngOnInit() {
    this.loadItem();
  }

  loadItem() {
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.isUpdating = true;

      Promise.all([
        this.internshipService
          .getDeptString()
          .then((res) => {
            this.deptString = res;
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),

        this.gradeService
          .getGradingScaleElement(this.zmcNotenskalaDataSourceObjectId)
          .then((items) => {
            const temp: GradingScaleElement[] = items;
            this.zmcNotenskala = [];
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  'E64C72C7-F2FB-4010-ACE4-99E5A7DC1F47'
              )
            );
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  '7270D0DA-50CC-4647-9FF9-C0E6B57B7356'
              )
            );
            this.zmcNotenskala.push(
              temp.find(
                (item) =>
                  item.dataSourceObjectId ===
                  '15D05230-8205-4A6D-9092-EA3B107EE244'
              )
            );
            if (this.zmcNotenskala.includes(undefined)) {
              this.zmcNotenskala = items;
            }
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),

        this.internshipService
          .UserHasStudentRole(id)
          .then((usergroups) => {
            this.usergroups = usergroups;
            this.isStudent =
              usergroups.dataSourceObjectId ===
              'BA3363BE-10CD-4CE2-B831-EC7E34D7BBA8';
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdating = false;
          }),

        this.internshipService
          .getInternshipDetail(id)
          .then((item) => {
            this.item = item;
            this.initItem();
            this.infoWorkingHours = this.getHelpTextFromKey(
              'CstInfoNetWorkingHours'
            );
            this.infoRemarks = this.getHelpTextFromKey('CstInfoRemarks');
            this.infoAbsences = this.getHelpTextFromKey('CstInfoAbsences');
            this.infoFurtherLerning = this.getHelpTextFromKey(
              'CstInfoFurtherLearning'
            );
          })
          .catch((error) => {
            this.isUpdating = false;
            this.setError(error);
          }),
      ]).then(() => {
        this.isUpdating = false;
      });
    }
  }

  initItem() {
    const state = this.item.workflowState.dataSourceObjectId;
    this.isQualifikationState = state === this.qualifikationDataSourceObjectId;
    this.itemReadonlyForIHG =
      this.item?.readonly || this.isStudent || !this.isQualifikationState;

    this.isPm1OrPm2 =
      this.item.internshipType.includes('Praktikum 1') ||
      this.item.internshipType.includes('Praktikum 2');
    this.isPm3 = this.item.internshipType.includes('Praktikum 3');
  }

  save(): void {
    this.isUpdating = true;
    this.internshipService
      .updateInternshipDetail(this.item)
      .then((res) => {
        this.isUpdating = false;
        this.router.navigate([
          GlobalSettings.navigate.internshipOverview,
          this.item.id,
        ]);
      })
      .catch((error) => {
        this.isUpdating = false;
        this.setError(error);
      });
  }

  getHelpTextFromKey(key: string): string {
    if (this.item) {
      var filteredItems = this.item.itemDescriptions.filter(
        (item) => item.key == key
      );
      if (filteredItems.length > 0) {
        return filteredItems[0].value;
      }
    }
    return 'Keine Informationen vorhanden';
  }

  cancelEdit() {
    this.router.navigate([
      GlobalSettings.navigate.internshipOverview,
      this.item.id,
    ]);
  }

  setError(error: Error | any) {
    this.error = error;
    if (error == null) {
      this.fatalError = false;
      return;
    }
    this.fatalError = error.status === 403;
  }

  confirmationStudentChanged(event: MatCheckboxChange): void {
    if (event.checked) {
      const today = new Date();
      this.item.assessmentFormReceiptIs = today.toISOString().slice(0, 10);
    } else {
      this.item.assessmentFormReceiptIs = null;
    }
  }

  setGradeDateAndDiscretization(): void {
    //check discretization
    const grade = Number.parseFloat(this.item.grade);
    const discretization = 0.5;
    if (Number.isNaN(grade)) {
      this.isDiskreditierungRight = false;
    } else {
      this.isDiskreditierungRight = (grade / discretization) % 1 === 0;
    }
  }
}
