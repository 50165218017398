import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { GradeDetail } from '../models/grade-detail';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css'],
})
export class EvaluationComponent implements OnChanges {
  @Input() grade: GradeDetail = null;
  @Input() selectedValue: number = null;
  @Input() disabled: boolean = false;
  @Output() onSelect = new EventEmitter<number>();
  gradeValues: number[] = null;
  id: string;

  constructor(elm: ElementRef) {
    this.id = elm.nativeElement.getAttribute('id');
  }

  ngOnChanges() {
    this.gradeValues = [];
    if (this.grade == null) return;
    for (
      let i = this.grade.minValue;
      i <= this.grade.maxValue;
      i = i + this.grade.discretization
    ) {
      this.gradeValues.push(i);
    }
  }

  selected() {
    this.onSelect.emit(this.selectedValue);
  }
}
