import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { InternshipOverviewComponent } from './internship-overview.component';
import { InernshipOverviewRoutingModule } from './internship-overview.routing';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { ProcessingModule } from '../processing/processing.module';
import { InternshipInfoModule } from '../internship-info/internship-info.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditorModule } from 'primeng/editor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    SharedModule,
    InernshipOverviewRoutingModule,
    ErrorHandlerModule,
    ProcessingModule,
    InternshipInfoModule,
    MatExpansionModule,
    EditorModule,
    MatCheckboxModule,
    MatRadioModule,
  ],
  declarations: [InternshipOverviewComponent],
  providers: [],
  exports: [InternshipOverviewComponent],
})
export class InternshipOverviewModule {}
