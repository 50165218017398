<dl-header style="width: 100%"></dl-header>
<div class="container-fluid px-5">
  <div class="content">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  <footer>
    <p>&copy; {{ year }} - daylight AG</p>
  </footer>
</div>
