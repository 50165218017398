import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { GradeDetailFormComponent } from './grade-detail-form.component';
import { GlobalSettings } from '../../shared/global-settings';

const gradeDetailRoutes: Routes = [
  {
    path: GlobalSettings.routes.gradeDetail,
    component: GradeDetailFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(gradeDetailRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class GradeDetailFormRoutingModule {}
