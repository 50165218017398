import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const azureAD = 'AzureAD';
export const switchEduID = 'SwitchEduID';

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.angularAppId,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  praxistoolApi: {
    endpoints: [
      `${environment.apiUrl}/api/pb/grade`,
      `${environment.apiUrl}/api/pb/registration`,
      `${environment.apiUrl}/api/pb/report`,
      `${environment.apiUrl}/api/pb/trainer`,
      `${environment.apiUrl}/api/pb/workflow`,
    ],
    scopes: [`api://${environment.webApiAppId}/access`],
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile'],
};

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://login.eduid.ch',

  // URL of the SPA to redirect the user to after login
  redirectUri: 'https://praxisbeurteilung.zhaw.ch/',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'zhaw_oidc_client_praxisbeurteilung-prod',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: 'hcysSPr6EAegWEpR47dTUdaiifTcViAQr', // Für Prod

  responseType: 'code',

  useSilentRefresh: true,

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope:
    'openid profile email https://login.eduid.ch/authz/User.Read offline_access',

  showDebugInformation: true,

  skipIssuerCheck: true,
  // turn off validation that discovery document endpoints start with the issuer url defined above
  //strictDiscoveryDocumentValidation: false,

  silentRefreshRedirectUri:
    'https://praxisbeurteilung.zhaw.ch/silent-refresh.html',
  silentRefreshShowIFrame: true,

  revocationEndpoint: 'https://login.eduid.ch/idp/profile/oauth2/revocation',
  userinfoEndpoint: 'https://login.eduid.ch/idp/profile/oidc/userinfo',
  redirectUriAsPostLogoutRedirectUriFallback: true,
  postLogoutRedirectUri: 'https://praxisbeurteilung.zhaw.ch/',
  tokenEndpoint: 'https://login.eduid.ch/idp/profile/oidc/token',
}; /*

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://login.eduid.ch',

  // URL of the SPA to redirect the user to after login
  redirectUri: 'https://praxisbeurteilung-test.zhaw.ch/',

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'zhaw_oidc_client_praxisbeurteilung-test',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: 'hE6Rl9ewS7r84Vd6Xr78nmneVmv8sS5Ch', // Für Test

  responseType: 'code',

  useSilentRefresh: true,

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope:
    'openid profile email https://login.eduid.ch/authz/User.Read offline_access',

  showDebugInformation: true,

  skipIssuerCheck: true,
  // turn off validation that discovery document endpoints start with the issuer url defined above
  //strictDiscoveryDocumentValidation: false,

  silentRefreshRedirectUri:
    'https://praxisbeurteilung-test.zhaw.ch/silent-refresh.html',
  silentRefreshShowIFrame: true,

  revocationEndpoint: 'https://login.eduid.ch/idp/profile/oauth2/revocation',
  userinfoEndpoint: 'https://login.eduid.ch/idp/profile/oidc/userinfo',
  redirectUriAsPostLogoutRedirectUriFallback: true,
  postLogoutRedirectUri: 'https://praxisbeurteilung-test.zhaw.ch/',
  tokenEndpoint: 'https://login.eduid.ch/idp/profile/oidc/token',
};*/
