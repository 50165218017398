<app-processing [isUpdating]="isUpdating"></app-processing>
<h1>&Uuml;bersicht Praxisverh&auml;ltnisse</h1>
<app-error-handler [error]="error"></app-error-handler>
<div class="{{ isUpdating ? 'loading' : '' }}">
  <div style="padding-bottom: 5px">
    <label for="withArchive">Archiv berücksichtigen &nbsp;</label>
    <mat-checkbox
      id="withArchive"
      name="withArchive"
      [(ngModel)]="this.withArchive"
      (change)="customFilterChanged()"
    ></mat-checkbox>
  </div>

  <p-table
    #internshipDataTable
    [value]="internships"
    [lazy]="true"
    [rows]="pageSize"
    [paginator]="true"
    [totalRecords]="totalRecords"
    (onLazyLoad)="loadLazy($event)"
    [scrollable]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="organizationnamecomp">
            Organisation
            <p-sortIcon field="organizationnamecomp"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="organizationnamecomp"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="studentnamecomp">
            Studierende/r
            <p-sortIcon field="studentnamecomp"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="studentnamecomp"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="trainernamecomp">
            Ausbildende/r
            <p-sortIcon field="trainernamecomp"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="trainernamecomp"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="mentornamecomp">
            Begleitende/r
            <span *ngIf="deptString === 'IHB'"
              >(Ausbildungsverantwortliche/r)</span
            >
            <p-sortIcon field="mentornamecomp"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="mentornamecomp"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="internshipType">
            Typ
            <p-sortIcon field="internshipType"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="internshipType"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="workflowstateName">
            Status
            <p-sortIcon field="workflowstateName"></p-sortIcon>
          </div>
          <p-columnFilter
            type="text"
            field="workflowstateName"
            [showMenu]="false"
            matchMode="contains"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="startDate">
            Startdatum
            <p-sortIcon field="startDate"></p-sortIcon>
          </div>
          <p-columnFilter
            type="date"
            field="startDate"
            display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 30px; padding-left: 8px">
          <div pSortableColumn="endDate">
            Enddatum
            <p-sortIcon field="endDate"></p-sortIcon>
          </div>
          <p-columnFilter
            type="date"
            field="endDate"
            display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false"
          ></p-columnFilter>
        </th>
        <th style="min-width: 90px" alignFrozen="right" pFrozenColumn>
          &nbsp;
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-internship let-rowIndex="rowIndex">
      <tr [pSelectableRow]="internship" [pSelectableRowIndex]="rowIndex">
        <td style="min-width: 30px">
          {{ internship.practiceOrganization?.name }}
        </td>

        <td style="min-width: 30px">
          {{ internship.student?.nameComputed }}
        </td>

        <td style="min-width: 30px">
          {{ internship.practiceEducator?.nameComputed }}
        </td>

        <td style="min-width: 30px">
          {{ internship.studySupport?.nameComputed }}
        </td>

        <td style="min-width: 30px">
          {{ internship.internshipType }}
        </td>

        <td style="min-width: 30px">
          {{ internship.workflowState?.name }}
        </td>

        <td style="min-width: 30px">
          {{ internship.startDate | date : "dd.MM.yyyy" }}
        </td>

        <td style="min-width: 30px">
          {{ internship.endDate | date : "dd.MM.yyyy" }}
        </td>

        <td
          class="functionicons"
          alignFrozen="right"
          pFrozenColumn
          style="min-width: 90px"
        >
          <button
            type="button"
            (click)="openPdfInNewTab(internship)"
            class="btn"
          >
            <i
              matTooltip="PDF &ouml;ffnen"
              class="fas fa-download bluecolor"
            ></i></button
          ><a [routerLink]="['overview', internship.id]"
            ><i matTooltip="Editieren" class="fas fa-edit bluecolor"></i
          ></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
