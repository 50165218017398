<div
  style="padding: 17px"
  *ngIf="error"
  class="alert alert-{{ alertStyle }}  alert-dismissable"
>
  <div style="min-height: 46px">
    <button
      type="button"
      class="btn btn-secondary float-right"
      *ngIf="redirectUrl"
      routerLink="{{ redirectUrl }}"
    >
      Schliessen &times;
    </button>
    <button
      type="button"
      class="btn btn-secondary float-right"
      *ngIf="!redirectUrl"
      (click)="closeAlert()"
    >
      Schliessen &times;
    </button>

    <div *ngIf="errorMessage && !error.status">
      {{ errorMessage }}
    </div>
    <div *ngIf="error.status">
      <b>{{ error.status }}</b>
      {{ errorMessage }}
    </div>
    <div *ngIf="error.fileName">
      <b>{{ error.fileName }}: {{ error.lineNumber }}</b>
      {{ error.message }}
    </div>
  </div>
</div>
