import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { InternshipDetailFormComponent } from './internship-detail-form.component';
import { InernshipDetailFormRoutingModule } from './internship-detail-form.routing';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { ProcessingModule } from '../processing/processing.module';
import { InternshipInfoModule } from '../internship-info/internship-info.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { EditorModule } from 'primeng/editor';

@NgModule({
  imports: [
    SharedModule,
    InernshipDetailFormRoutingModule,
    ReactiveFormsModule,
    ErrorHandlerModule,
    ProcessingModule,
    InternshipInfoModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    EditorModule,
  ],
  declarations: [InternshipDetailFormComponent],
  providers: [],
  exports: [InternshipDetailFormComponent],
})
export class InternshipDetailFormModule {}
