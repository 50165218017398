import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { lastValueFrom, throwError } from 'rxjs';

import { getErrorMessage } from '../../layout/shared/shared.tools';
import { GridOptions } from '../models/grid-options';
import { GridRequest } from '../models/grid-request';

@Injectable({
  providedIn: 'root',
})
export class TrainerService {
  constructor(private http: HttpClient) {}

  readonly extendedUserEndpoint: string = `${environment.apiUrl}/api/pb/trainer/GetListAsync`;
  gridOptions: GridOptions;

  getList(gridRequest: GridRequest): Promise<any> {
    var data = JSON.stringify(gridRequest);
    let config: Object = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    return lastValueFrom(
      this.http
        .post<any>(this.extendedUserEndpoint, data, config)
        .pipe(
          catchError((error) =>
            throwError(
              () =>
                new Error(
                  getErrorMessage(
                    error,
                    'Praxisausbildende konnten nicht gelesen werden.'
                  )
                )
            )
          )
        )
    );
  }
}
