import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { InternshipInfoComponent } from './internship-info.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule],
  declarations: [InternshipInfoComponent],
  providers: [],
  exports: [InternshipInfoComponent],
})
export class InternshipInfoModule {}
