<app-error-handler [error]="error"></app-error-handler>
<app-processing [isUpdating]="isUpdating"></app-processing>
<div
  *ngIf="!fatalError && !isUpdating && item != null"
  class="container-fluid {{ isUpdating ? 'loading' : '' }}"
>
  <internship-info [item]="item"></internship-info>
  <mat-accordion
    *ngIf="deptString === 'IHB'"
    class="example-headers-align"
    multi
  >
    <h4 class="mb-2" *ngIf="item.isContactEducation && isStudienbegleitende">
      Praxisausbildende:r
      <button class="btn" (click)="openTrainers()">
        <i class="fas fa-edit bluecolor"></i>
      </button>
    </h4>

    <div *ngIf="isPm1OrPm2">
      <h4>Allgemeine Informationen</h4>

      Die Leistungsbeurteilung ist mit einer numerischen Gesamtnote zwischen 1
      und 6 zu bewerten, wobei halbe Noten vergeben werden können. Diese
      Gesamtnote bildet die erbrachte Leistung im Praxismodul ab.
      <br />
      Der Leistungsnachweis ist bestanden, wenn mindestens die Note 4 erreicht
      wird. Im ersten Praxismodul wird die <b>Kompetenz Ab4</b> nicht beurteilt.
      <br />
      In der Taxonomiestufe II macht die Berufsanfängerin erste Erfahrungen im
      Berufsalltag. Sie wendet die einzelnen Tätigkeiten im Berufskontext an und
      erlangt ein Prozessverständnis.
      <br />
      <br />
      <b>Die Leistungsbeurteilung erfolgt anhand folgender Punkte:</b>
      <ul>
        <li>Modulhandbuch des jeweiligen Praxismoduls</li>
        <li>„Eintrittsgespräch“</li>
        <li>„Standortgespräch“</li>
        <li>„Beurteilung Praxismodulabschluss“</li>
        <li>Portfolio der Studierenden: Reflexion zur Lernprozessgestaltung</li>
        <li>
          CanMEDS-Rollenmodell und Professionsspezifische Kompetenzen des
          Studienganges Hebamme
        </li>
      </ul>
    </div>
    <div *ngIf="isPm3">
      <h4>Allgemeine Informationen</h4>

      Die Leistungsbeurteilung ist mit einer numerischen Gesamtnote zwischen 1
      und 6 zu bewerten, wobei halbe Noten vergeben werden können. Diese
      Gesamtnote bildet die erbrachte Leistung im Praxismodul ab.
      <br />
      Der Leistungsnachweis ist bestanden, wenn mindestens die Note 4 erreicht
      wird.
      <br />
      In der Taxonomiestufe III vertieft die Studentin Ihre Erfahrungen im
      Berufsalltag. Sie wendet die Handlungsabläufe und Tätigkeiten im
      Berufskontext an und erlangt ein erweitertes Prozessverständnis.
      <br />
      <br />
      <b>Die Leistungsbeurteilung erfolgt anhand folgender Punkte:</b>
      <ul>
        <li>Modulhandbuch des jeweiligen Praxismoduls</li>
        <li>„Eintrittsgespräch“</li>
        <li>„Standortgespräch“</li>
        <li>„Beurteilung Praxismodulabschluss“</li>
        <li>Portfolio der Studierenden: Reflexion zur Lernprozessgestaltung</li>
        <li>
          CanMEDS-Rollenmodell und Professionsspezifische Kompetenzen des
          Studienganges Hebamme
        </li>
      </ul>
    </div>

    <mat-expansion-panel [expanded]="isEntranceInterview">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 [ngClass]="isEntranceInterview ? 'black' : 'greyedOut'">
            Eintrittsgespräch &nbsp;&nbsp;&nbsp;&nbsp;
          </h2>
          <button
            (click)="reopenEntranceInterview($event)"
            class="btn btn-primary float-right"
            *ngIf="
              !isStudent &&
              (isStatusReview1 ||
                (isEntranceInterview &&
                  (item.entranceInterviewStudent ||
                    item.entranceInterviewTrainer)))
            "
          >
            Bearbeiten
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [class.disabled]="isUpdating">
        <p>
          Das Protokoll wird von der/dem Studierenden geschrieben und von
          der/dem Praxisausbildenden und der/dem Studierenden unterschrieben.
        </p>
        <p-editor
          [style]="{ height: 'auto' }"
          id="entranceInterview"
          name="entranceInterview"
          [(ngModel)]="this.item.entranceInterview"
          [readonly]="
            !isEntranceInterview ||
            item?.readonly ||
            item.entranceInterviewStudent ||
            item.entranceInterviewTrainer
          "
          spellcheck="false"
        >
          <ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
        <div style="margin-top: 10px">
          <label for="entranceInterviewStudent"
            >Bestätigung Studierende:r &nbsp;</label
          >
          <mat-checkbox
            id="entranceInterviewStudent"
            name="entranceInterviewStudent"
            [(ngModel)]="this.item.entranceInterviewStudent"
            [disabled]="!isEntranceInterview || !item?.editableStudent"
          ></mat-checkbox>
          &nbsp;&nbsp;
          <label for="entranceInterviewTrainer"
            >Bestätigung Praxisausbildende:r &nbsp;</label
          >
          <mat-checkbox
            id="entranceInterviewTrainer"
            name="entranceInterviewTrainer"
            [(ngModel)]="this.item.entranceInterviewTrainer"
            [disabled]="!isEntranceInterview || !item?.editableTrainer"
          ></mat-checkbox>
          <button
            style="margin-top: 15px !important"
            (click)="save()"
            class="btn btn-primary float-right"
            [disabled]="!isEntranceInterview"
          >
            Speichern
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="isStatusReview1">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 [ngClass]="isStatusReview1 ? 'black' : 'greyedOut'">
            Standortgespräch 1 &nbsp;&nbsp;&nbsp;&nbsp;
          </h2>
          <button
            (click)="reopenStatusReview1($event)"
            class="btn btn-primary float-right"
            *ngIf="
              !isStudent &&
              (isStatusReview2 ||
                (isStatusReview1 &&
                  (item.statusReview1Student || item.statusReview1Trainer)))
            "
          >
            Bearbeiten
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [class.disabled]="isUpdating">
        <p>
          Das Protokoll wird von der/dem Studierenden geschrieben und von
          der/dem Praxisausbildenden und der/dem Studierenden unterschrieben.
        </p>
        <p-editor
          [style]="{ height: 'auto' }"
          id="statusReview1"
          name="statusReview1"
          [(ngModel)]="this.item.statusReview1"
          [readonly]="
            !isStatusReview1 ||
            item?.readonly ||
            item.statusReview1Student ||
            item.statusReview1Trainer
          "
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
        <div style="margin-top: 10px">
          <label for="entranceInterviewStudent"
            >Bestätigung Studierende:r &nbsp;</label
          >
          <mat-checkbox
            id="statusReview1Student"
            name="statusReview1Student"
            [(ngModel)]="this.item.statusReview1Student"
            [disabled]="!isStatusReview1 || !item?.editableStudent"
          ></mat-checkbox>
          &nbsp;&nbsp;
          <label for="entranceInterviewTrainer"
            >Bestätigung Praxisausbildende:r &nbsp;</label
          >
          <mat-checkbox
            id="statusReview1Trainer"
            name="statusReview1Trainer"
            [(ngModel)]="this.item.statusReview1Trainer"
            [disabled]="!isStatusReview1 || !item?.editableTrainer"
          ></mat-checkbox>
          <button
            style="margin-top: 15px !important"
            (click)="save()"
            class="btn btn-primary float-right"
            [disabled]="!isStatusReview1"
          >
            Speichern
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="isStatusReview2">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 [ngClass]="isStatusReview2 ? 'black' : 'greyedOut'">
            Standortgespräch 2 (fakultativ)&nbsp;&nbsp;&nbsp;&nbsp;
          </h2>
          <button
            (click)="reopenStatusReview2($event)"
            class="btn btn-primary float-right"
            *ngIf="
              !isStudent &&
              isNeuState &&
              (item.statusReview2Student ||
                item.statusReview2Trainer ||
                item.noStatusReview2)
            "
          >
            Bearbeiten
          </button>
          <mat-checkbox
            id="noStatusReview2"
            name="noStatusReview2"
            [(ngModel)]="this.item.noStatusReview2"
            [disabled]="!isStatusReview2 || item?.readonly || isStudent"
            (click)="$event.stopPropagation()"
            >Nicht nötig</mat-checkbox
          >
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [class.disabled]="isUpdating">
        <p>
          Das Protokoll wird von der/dem Studierenden geschrieben und von
          der/dem Praxisausbildenden und der/dem Studierenden unterschrieben.
        </p>
        <p-editor
          [style]="{ height: 'auto' }"
          id="statusReview2"
          name="statusReview2"
          [(ngModel)]="this.item.statusReview2"
          [readonly]="
            !isStatusReview2 ||
            item?.readonly ||
            item.statusReview2Student ||
            item.statusReview2Trainer ||
            item.noStatusReview2
          "
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
        <div style="margin-top: 10px">
          <label for="entranceInterviewStudent"
            >Bestätigung Studierende:r &nbsp;</label
          >
          <mat-checkbox
            id="statusReview2Student"
            name="statusReview2Student"
            [(ngModel)]="this.item.statusReview2Student"
            [disabled]="
              !isStatusReview2 || !item?.editableStudent || item.noStatusReview2
            "
          ></mat-checkbox>
          &nbsp;&nbsp;
          <label for="entranceInterviewTrainer"
            >Bestätigung Praxisausbildende:r &nbsp;</label
          >
          <mat-checkbox
            id="statusReview2Trainer"
            name="statusReview2Trainer"
            [(ngModel)]="this.item.statusReview2Trainer"
            [disabled]="
              !isStatusReview2 || !item?.editableTrainer || item.noStatusReview2
            "
          ></mat-checkbox>
          <button
            style="margin-top: 15px !important"
            (click)="save()"
            class="btn btn-primary float-right"
            [disabled]="!isStatusReview2"
          >
            Speichern
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <h2
    [ngClass]="
      deptString !== 'IHB' || isQualifikationState ? 'black' : 'greyedOut'
    "
  >
    Beurteilungen<a
      *ngIf="deptString !== 'IHB' || !isQualifikationState || !isStudent"
      (click)="openPdfInNewTab(item.id)"
      class="icon-pdf"
    ></a>
  </h2>

  <div *ngIf="isQualifikationState && isStudent">
    Die Beurteilungen können von Studierenden nicht eingesehen werden während
    der Erstellung der Beurteilung.
  </div>

  <div *ngIf="!isQualifikationState || !isStudent">
    <div>
      <div
        *ngFor="let evaluationGroup of item.evaluationGroups"
        class="col-md-6 grade-group"
      >
        <h3>{{ evaluationGroup.name }}</h3>
        <p>{{ evaluationGroup.description }}</p>
        <div *ngFor="let grade of evaluationGroup.evaluations" class="row">
          <div class="col-md-9">
            <a
              routerLink="/grade/detail/{{ grade.id }}"
              class="{{ grade.inputMissing ? 'inputMissing' : 'inputSet' }}"
              >{{ grade.name }}</a
            >
          </div>
          <div
            class="col-md-3 right"
            *ngIf="item?.showPointsDashboardAndGradeAverage"
          >
            Punkte: {{ grade.pointsDashboard }}
          </div>
        </div>
        <div class="row right" *ngIf="item?.showPointsDashboardAndGradeAverage">
          <div class="col-md-12 right">
            Bewertungsdurchschnitt: {{ evaluationGroup.gradeAverage }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="item.evaluation">
      <h2>Ergebnis</h2>
      <h4>
        Notendurchschnitt: <b>{{ item.evaluation }}</b>
      </h4>
    </div>
    <div *ngIf="item.achievedPoints">
      <h4>
        Erreichte Punkte: <b>{{ item.achievedPoints }}</b>
      </h4>
    </div>
  </div>

  <h2
    [ngClass]="
      deptString !== 'IHB' || isQualifikationState ? 'black' : 'greyedOut'
    "
  >
    Allgemeine Angaben<a
      *ngIf="
        !item.readonly &&
        (deptString !== 'IHB' || (isQualifikationState && !isStudent))
      "
      routerLink="/internships/detail/{{ item.id }}"
      class="icon-edit"
    ></a>
  </h2>

  <div *ngIf="deptString === 'S'">
    <div class="row">
      <div class="col-md-6">
        <label>Beurteilungsbogen Abgabe</label>
        <label class="form-control disabled">{{
          item.assessmentSheetDeliveryShould | date : "dd.MM.yyyy"
        }}</label>
      </div>
      <div class="col-md-6" *ngIf="item?.showTargetAgreementDeliveryShould">
        <label>Zielvereinbarung Abgabe</label>
        <label class="form-control disabled">{{
          item.targetAgreementDeliveryShould | date : "dd.MM.yyyy"
        }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="item?.showNetWorkingHours">
        <label for="netWorkingHours">Nettoarbeitsstunden</label>
        <input
          class="form-control"
          name="netWorkingHours"
          [(ngModel)]="item.netWorkingHours"
          id="netWorkingHours"
          [readonly]="true"
          [disabled]="true"
        />
      </div>
      <div class="col-md-6" *ngIf="item?.showAbsences">
        <label for="absences">Absenzen / Tage</label>
        <input
          class="form-control"
          name="absences"
          [(ngModel)]="item.absences"
          id="absences"
          [readonly]="true"
          [disabled]="true"
        />
      </div>
    </div>
    <div class="row" *ngIf="item?.showRemarks">
      <div class="col-md-12">
        <label>Bemerkungen</label>
        <div
          class="form-control wysiwyg disabled"
          [innerHTML]="item.remarks"
        ></div>
      </div>
    </div>
    <div class="row" *ngIf="item?.showFurtherLearnings">
      <div class="col-md-12">
        <label
          >Weiterf&uuml;hrende Lernfelder / Empfehlungen f&uuml;r das
          n&auml;chste Praktikum</label
        >
        <div
          class="form-control wysiwyg disabled"
          [innerHTML]="item.furtherLearnings"
        ></div>
      </div>
    </div>
  </div>

  <div *ngIf="deptString === 'IHB' && isQualifikationState && isStudent">
    Die Allgemeinen Angaben können von Studierenden nicht eingesehen werden
    während der Erstellung der Beurteilung.
  </div>

  <div *ngIf="deptString === 'IHB' && (!isQualifikationState || !isStudent)">
    <!-- PM1 + PM2 -->
    <div
      *ngIf="
        item?.typeDataSourceObjectId ==
          '16692B2A-6AC5-416D-B998-FD753D443CCD' ||
        item?.typeDataSourceObjectId == '437FAB66-2376-40F3-8F64-B5F2DC463650'
      "
    >
      Diese Beurteilung basiert auf der Taxonomiestufe II
    </div>
    <!-- PM3 -->
    <div
      *ngIf="
        item?.typeDataSourceObjectId == 'A7F57560-CD98-4070-9C2D-E32819F81A2B'
      "
    >
      Diese Beurteilung basiert auf der Taxonomiestufe III
    </div>
    <!-- ZMC -->
    <div *ngIf="item?.showOverallGrade456">
      Diese Beurteilung basiert auf der Taxonomiestufe IV. Der Taxonomieraster
      ist ein Hilfstool, um die Bewertung vorzunehmen. Diesen finden Sie in den
      allgemeinen Grundlagen des jeweiligen Studiengangs.
    </div>

    <div class="col-md-6" *ngIf="item?.showOverallGrade123">
      <label for="grade"
        >Gesamtnote:<span
          class="red"
          *ngIf="
            item?.showOverallGrade123 &&
            (item.grade === null || item.grade == '' || item.grade == '0')
          "
        >
          *
        </span></label
      >
      <input
        class="form-control"
        name="grade"
        [(ngModel)]="item.grade"
        id="grade"
        size="40"
        [disabled]="true"
      />
    </div>

    <div class="row" *ngIf="item?.showOverallGrade456">
      <label for="passedStates"
        >Status:<span
          class="red"
          *ngIf="
            item?.showOverallGrade456 &&
            (item.zmcGradeId === null || item.zmcGradeId === '')
          "
        >
          *
        </span></label
      >
      <mat-radio-group
        name="passedStates"
        [(ngModel)]="item.zmcGradeId"
        [disabled]="true"
      >
        <div class="col-sm">
          <mat-radio-button
            [checked]="passedState.dataSourceObjectId === item.zmcGradeId"
            *ngFor="let passedState of zmcNotenskala"
            [value]="passedState.dataSourceObjectId"
          >
            {{ passedState.name }}&nbsp; &nbsp;
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="col-md-6" *ngIf="item?.showAbsences">
      <label for="absenceInDays"
        >Absenzen (Anzahl Tage)<span
          class="red"
          *ngIf="item.absences === null || item.absences == ''"
        >
          *
        </span></label
      >
      <input
        class="form-control"
        name="absenceInDays"
        [(ngModel)]="item.absences"
        id="absenceInDays"
        [readonly]="true"
        [disabled]="true"
      />
    </div>

    <div
      class="col-md-6"
      *ngIf="item?.showReasonForAbsences && !isPm1OrPm2 && !isPm3"
    >
      <label for="reasonForAbsences"
        >Grund für Absenz<span
          class="red"
          *ngIf="
            item.reasonForAbsences === null || item.reasonForAbsences == ''
          "
        >
          *
        </span></label
      >
      <input
        class="form-control"
        name="reasonForAbsences"
        [(ngModel)]="item.reasonForAbsences"
        id="reasonForAbsences"
        [readonly]="true"
        [disabled]="true"
      />
    </div>

    <div class="row" *ngIf="item?.showOverallAssessment">
      <div class="col-md-12">
        <label
          ><span *ngIf="item?.showOverallGrade123"
            >Modulabschlussbeurteilung (Zusammenfassung der
            Gesamtleistung)</span
          ><span *ngIf="item?.showOverallGrade456"
            >Abschlussbeurteilung (Zusammenfassung der Gesamtleitung)</span
          ><span
            class="red"
            *ngIf="
              item.overallAssessment === null || item.overallAssessment === ''
            "
          >
            *
          </span></label
        >
        <p-editor
          [style]="{ height: 'auto' }"
          id="overallAssessment"
          name="overallAssessment"
          [(ngModel)]="this.item.overallAssessment"
          [readonly]="true"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>

    <div class="row" *ngIf="item?.showDevelopmentMeasures">
      <div class="col-md-12">
        <label
          ><span *ngIf="item?.showOverallGrade123"
            >Entwicklungs- und Förderungsmassnahmen für künftige
            Praxismodule</span
          ><span *ngIf="item?.showOverallGrade456"
            >Ziele für die Weiterentwicklung</span
          ><span
            class="red"
            *ngIf="
              item.developmentMeasures === null ||
              item.developmentMeasures === ''
            "
          >
            *
          </span></label
        >
        <p-editor
          [style]="{ height: 'auto' }"
          id="developmentMeasures"
          name="developmentMeasures"
          [(ngModel)]="this.item.developmentMeasures"
          [readonly]="true"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="assessmentFormReceiptIs"
          >Beurteilungsbogen eingereicht (Datum)</label
        ><input
          type="date"
          class="form-control"
          name="assessmentFormReceiptIs"
          [(ngModel)]="item.assessmentFormReceiptIs"
          id="assessmentFormReceiptIs"
          [disabled]="true"
        />
      </div>
    </div>

    <div style="padding-top: 12px">
      <label
        for="confirmationStudent"
        [ngClass]="isFreigabeState ? 'black' : 'greyedOut'"
        >Bestätigung Studierende:r</label
      >
      <mat-checkbox
        id="confirmationStudent"
        name="confirmationStudent"
        [(ngModel)]="item.confirmationStudent"
        [disabled]="!item?.editableStudent || !isFreigabeState"
        (change)="confirmationStudentChanged($event)"
      ></mat-checkbox>
      <label
        for="confirmationTrainer"
        [ngClass]="isFreigabeState ? 'black' : 'greyedOut'"
        >Bestätigung Praxisausbildende:r</label
      >
      <mat-checkbox
        id="confirmationStudent"
        name="confirmationTrainer"
        [(ngModel)]="item.confirmationTrainer"
        [disabled]="!item?.editableTrainer || !isFreigabeState"
      ></mat-checkbox>

      <button
        style="margin-top: 15px !important"
        (click)="save()"
        class="btn btn-primary float-right"
        *ngIf="isFreigabeState"
      >
        Speichern
      </button>
    </div>
  </div>

  <div class="workflowSteps">
    <button
      type="button"
      class="btn btn-primary"
      *ngFor="let workFlowStep of item.executableWorkflowSteps"
      (click)="
        executeWorkflowStep(
          item.id,
          workFlowStep.id,
          workFlowStep.reverse,
          workFlowStep.dataSourceObjectId
        )
      "
    >
      {{
        workFlowStep.reverse
          ? "Zur&uuml;ck zum Status '" + workFlowStep.name + "'"
          : workFlowStep.name
      }}
    </button>
  </div>
</div>
