import { Component, Input, OnChanges } from '@angular/core';
import { GlobalSettings } from '../../shared/global-settings';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.css'],
})
export class ErrorHandlerComponent implements OnChanges {
  @Input() error: Error | any;
  alertStyle: string = 'warning';
  redirectUrl: string = null;
  errorMessage: string = null;

  constructor() {}

  ngOnChanges() {
    this.alertStyle = 'warning';
    this.redirectUrl = null;
    if (!this.error) return;
    if (this.error.status == 403) {
      this.alertStyle = 'danger';
      this.redirectUrl = '/internships';
    }
    if (this.error.message) {
      this.errorMessage = this.error.message;
    } else if (this.error.status) {
      try {
        var body = JSON.parse(this.error._body);
        this.errorMessage = body.message ? body.message : body;
      } catch (e) {
        this.errorMessage = this.error._body;
      }
    }
    let _this = this;
    if (GlobalSettings.alertDuration < 0) return;
    setTimeout(function () {
      _this.error = null;
    }, GlobalSettings.alertDuration);
  }

  closeAlert() {
    this.error = null;
  }
}
