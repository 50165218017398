import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProcessingComponent } from '../processing/processing.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule],
  declarations: [ProcessingComponent],
  providers: [],
  exports: [ProcessingComponent],
})
export class ProcessingModule {}
