import { Component, Input, OnChanges } from '@angular/core';
import { InternshipDetail } from '../models/internship-detail';

@Component({
  selector: 'internship-info',
  templateUrl: './internship-info.component.html',
  styleUrls: ['./internship-info.component.css'],
})
export class InternshipInfoComponent implements OnChanges {
  @Input() item: InternshipDetail;
  text: string;
  status: string;

  constructor() {}

  ngOnChanges() {
    if (this.item) {
      //this.item = InternshipDetailFactory.fromObject(this.item);
      this.text = this.getInfoString();
      if (this.item.workflowState) {
        this.status = this.item.workflowState.name;
      }
    }
  }

  getInfoString() {
    var seperator = ' - ';
    if (!this.item.student || !this.item.practiceOrganization) return '';
    return (
      this.item.student.nameComputed +
      seperator +
      this.item.internshipType +
      seperator +
      this.item.practiceOrganization.name
    );
  }
}
