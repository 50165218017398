import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Grade } from '../models/grade';
import { GradeDetail } from '../models/grade-detail';
import { InternshipDetail } from '../models/internship-detail';
import { EvaluationGroup } from '../models/evaluation-group';
import { InternshipService } from '../services/internship.service';
import { GradeService } from '../services/grade.service';
import { GlobalSettings } from '../../shared/global-settings';
import { UserGroup } from '../models/usergroup';

@Component({
  selector: 'app-grade-detail-form',
  templateUrl: './grade-detail-form.component.html',
  styleUrls: ['./grade-detail-form.component.css'],
})
export class GradeDetailFormComponent implements OnInit {
  item: GradeDetail = null;
  usergroups: UserGroup = null;

  parentInternship: InternshipDetail = null;
  evaluationGroup: EvaluationGroup = null;
  error: Error;
  isUpdatingDeptAndRole: boolean = false;
  isUpdatingGrade: boolean = false;
  isUpdatingParent: boolean = false;
  fatalError: boolean = false;
  gradeDetailForm: FormGroup;
  nextGrade: Grade = null;
  previousGrade: Grade = null;
  isStudent = true;
  qualifikationDataSourceObjectId = '0548FC6C-0C8B-499F-8098-0C3A894F4C44';
  isQualifikationState: boolean;
  deptString: string;

  constructor(
    private fb: FormBuilder,
    private internshipService: InternshipService,
    private gradeService: GradeService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    route.params.subscribe(() => this.loadItem());
  }

  ngOnInit() {
    this.loadItem();
  }

  isUpdating(): boolean {
    return (
      this.isUpdatingGrade ||
      this.isUpdatingParent ||
      this.isUpdatingDeptAndRole
    );
  }

  loadItem() {
    const id = this.route.snapshot.params['id'];

    this.isUpdatingDeptAndRole = true;
    this.isUpdatingGrade = true;
    this.isUpdatingParent = true;

    if (id) {
      Promise.all([
        this.internshipService
          .getDeptString()
          .then((res) => {
            this.deptString = res;
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdatingDeptAndRole = false;
          }),
        this.gradeService
          .UserHasStudentRole(id)
          .then((usergroups) => {
            this.usergroups = usergroups;
            this.isStudent =
              usergroups.dataSourceObjectId ===
              'BA3363BE-10CD-4CE2-B831-EC7E34D7BBA8';
          })
          .catch((error) => {
            this.setError(error);
            this.isUpdatingDeptAndRole = false;
          }),
        this.gradeService
          .getGradeDetail(id)
          .then((item) => {
            this.item = item;

            this.loadParent(id);
            this.isUpdatingGrade = false;
          })
          .catch((error) => {
            this.isUpdatingGrade = false;
            this.isUpdatingParent = false;
            this.setError(error);
          }),
      ]).then(() => {
        this.isUpdatingDeptAndRole = false;
        const state = this.item.workflowState.dataSourceObjectId;
        this.item.readonly =
          this.item.readonly ||
          (this.deptString === 'IHB' &&
            state !== this.qualifikationDataSourceObjectId);
        this.item.description = this.item.description
          .replace(new RegExp('{b}', 'g'), '<b>')
          .replace(new RegExp('{/b}', 'g'), '</b>')
          .replace(new RegExp('{br}', 'g'), '<br>');
      });
    }
  }

  initItem(item: GradeDetail) {
    this.gradeDetailForm = this.fb.group({
      objectives: item.objectives,
      objectiveDeadlines: item.objectiveDeadlines,
      indicators: item.indicators,
      indicatorDeadlines: item.indicatorDeadlines,
      exemplarySituation: item.exemplarySituation,
      interimAssessment1: item.interimAssessment1,
      interimAssessment2: item.interimAssessment2,
      valueFreeText: item.valueFreeText,
      valueDecimal: item.valueDecimal,
    });
  }

  loadParent(id: number) {
    this.internshipService
      .getInternshipDetailByGradeId(id)
      .then((parentInternship) => {
        this.parentInternship = parentInternship;
        this.initItem(this.item);
        this.determineEvaluationGroupAndSteps(id);
        this.isUpdatingParent = false;
      })
      .catch((error) => {
        this.isUpdatingParent = false;
        this.setError(error);
      });
  }

  determineEvaluationGroupAndSteps(id: number) {
    if (!this.parentInternship) return;
    try {
      this.parentInternship.evaluationGroups.forEach((group, groupIndex) => {
        group.evaluations.forEach((evaluation, evaluationIndex) => {
          if (evaluation.id != id) return;
          this.evaluationGroup = group;
          //previous
          if (evaluationIndex > 0) {
            this.previousGrade = group.evaluations[evaluationIndex - 1];
          } else {
            let previousGroup =
              this.parentInternship.evaluationGroups[
                (groupIndex > 0
                  ? groupIndex
                  : this.parentInternship.evaluationGroups.length) - 1
              ];
            this.previousGrade =
              previousGroup.evaluations[previousGroup.evaluations.length - 1];
          }
          //next
          if (evaluationIndex < group.evaluations.length - 1) {
            this.nextGrade = group.evaluations[evaluationIndex + 1];
          } else {
            let nextGroup =
              this.parentInternship.evaluationGroups[
                groupIndex < this.parentInternship.evaluationGroups.length - 1
                  ? groupIndex + 1
                  : 0
              ];
            this.nextGrade = nextGroup.evaluations[0];
          }
        });
      });
    } catch (error) {
      this.setError(error);
    }
  }

  save() {
    this.isUpdatingGrade = true;
    this.gradeService
      .updateGradeDetail(this.item)
      .then(() => {
        this.loadItem();
        this.internshipService.resetCache();
      })
      .catch((error) => {
        this.isUpdatingGrade = false;
        this.setError(error);
      });
  }

  cancelEdit() {
    this.router.navigate([
      GlobalSettings.navigate.internshipOverview,
      this.parentInternship.id,
    ]);
  }

  next() {
    this.save();
    this.router.navigate([
      GlobalSettings.navigate.gradeDetail,
      this.nextGrade.id,
    ]);
  }

  previous() {
    this.save();
    this.router.navigate([
      GlobalSettings.navigate.gradeDetail,
      this.previousGrade.id,
    ]);
  }

  setEvaluation(value: number) {
    this.item.valueDecimal = value;
  }

  setInterimAssessment1(value: number) {
    this.item.interimAssessment1 = value;
  }

  setInterimAssessment2(value: number) {
    this.item.interimAssessment2 = value;
  }

  setError(error: Error | any) {
    this.error = error;
    if (error == null) {
      this.fatalError = false;
      return;
    }
    this.fatalError = error.status === 403;
  }
}
