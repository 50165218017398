<header>
  <div class="navbar">
    <div style="width: 100px">
      <a routerLink="/internships"><div class="logo"></div></a>
    </div>
    <div class="container">
      <div class="row-container">
        <div class="app-title">Praxisbeurteilung</div>
      </div>
      <div class="row-container">
        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary"
          style="color: #0064a6; background-color: white"
          *ngIf="!loginDisplay"
          (click)="openInfoDialog()"
        >
          Informationen zum neuen Login
        </button>

        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item loginbutton"
          *ngIf="!loginDisplay"
          (click)="eduIdLogin()"
        >
          <img class="loginimage" src="assets/eduidbutton.png" />
        </button>
        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item loginbutton"
          *ngIf="!loginDisplay"
          (click)="azureLogin()"
        >
          <img class="loginimage" src="assets/ms_signin.png" />
        </button>
        <button
          *ngIf="loginDisplay"
          type="button"
          pButton
          pRipple
          class="btn btn-primary"
        >
          <a routerLink="/internships" routerLinkActive="active"
            >&Uuml;bersicht Praxisverh&auml;ltnisse</a
          >
        </button>
        <button
          type="button"
          pButton
          pRipple
          class="btn btn-primary nav-item"
          *ngIf="loginDisplay"
          placement="bottom-end"
          [matTooltip]="username"
        >
          <a (click)="logout()">Logout</a>
        </button>
      </div>
    </div>
  </div>
</header>
