import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css'],
})
export class ProcessingComponent {
  @Input() isUpdating: boolean | any;

  constructor() {}
}
