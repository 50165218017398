import { Injectable } from '@angular/core';
import { InternshipDetail } from '../models/internship-detail';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getErrorMessage } from '../../layout/shared/shared.tools';
import { environment } from '../../../environments/environment';

@Injectable()
export class WorkflowStepService {
  constructor(private http: HttpClient) {}

  executeWorkflowStepUrl =
    environment.apiUrl + '/api/pb/workflow/ExecuteWorkflowStep';

  executeWorkflowStep(
    registrationId: number,
    workflowStepId: number,
    reverse: boolean
  ): Promise<InternshipDetail> {
    let url: string = `${this.executeWorkflowStepUrl}?registrationId=${registrationId}&workflowStepId=${workflowStepId}&reverse=${reverse}`;

    return lastValueFrom(
      this.http
        .get<InternshipDetail>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }
}
