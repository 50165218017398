import { Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { OAuthStorage, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { BYPASS_MSAL } from './msal-skip.interceptor';
import { switchEduID } from '../auth-config';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes('login.eduid.ch')) {
      let modHeaders: any = {
        'Login-Method': `${this.authStorage.getItem('login_method')}`,
      };

      let url = request.url.toLowerCase();

      request = request.clone({ setHeaders: modHeaders });

      if (!this.moduleConfig) return next.handle(request);
      if (!this.moduleConfig.resourceServer) return next.handle(request);
      if (!this.moduleConfig.resourceServer.allowedUrls)
        return next.handle(request);
      if (!this.checkUrl(url)) return next.handle(request);

      if (this.authStorage.getItem('login_method') == switchEduID) {
        request.context.set(BYPASS_MSAL, true);
        let sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;
        if (sendAccessToken) {
          let token = this.authStorage.getItem('id_token');
          //console.log(this.authStorage.getItem('id_token'));
          //console.log(this.authStorage.getItem('access_token'));
          let header = 'Bearer ' + token;
          let headers = request.headers.set('Authorization', header);
          let access_token = this.authStorage.getItem('access_token');
          if (access_token !== null) {
            headers = headers.set('access_token', access_token);
          }

          request = request.clone({ headers });
        }
      }
    }

    return next.handle(request);
  }

  private checkUrl(url: string): boolean {
    let found = this.moduleConfig.resourceServer.allowedUrls.find((u) =>
      url.startsWith(u)
    );
    return !!found;
  }
}
