<h1>Allgemeine Angaben</h1>
<app-error-handler [error]="error"></app-error-handler>
<app-processing [isUpdating]="isUpdating"></app-processing>
<div
  *ngIf="!fatalError && !isUpdating && item != null"
  class="container-fluid {{ isUpdating ? 'loading' : '' }}"
>
  <internship-info [item]="item"></internship-info>

  <div *ngIf="deptString === 'S'">
    <div class="row">
      <div class="col-md-6" *ngIf="item?.showNetWorkingHours">
        <div>
          <label for="netWorkingHours"
            >Nettoarbeitsstunden<span
              class="red"
              *ngIf="item.netWorkingHours === null"
            >
              *
            </span></label
          >
        </div>
        <input
          class="form-control"
          id="netWorkingHours"
          [(ngModel)]="item.netWorkingHours"
          [readonly]="item?.readonly || isStudent"
        />
      </div>
      <div class="col-md-6" *ngIf="item?.showAbsences">
        <label for="absences">Absenzen / Tage</label>
        <input
          class="form-control"
          id="absences"
          [(ngModel)]="item.absences"
          [readonly]="item?.readonly"
        />
      </div>
    </div>
    <div class="row" *ngIf="item?.showRemarks">
      <div class="col-md-12" [class.disabled]="isUpdating">
        <label for="remarks">Bemerkungen &nbsp;</label>
        <i
          *ngIf="infoRemarks != null && infoRemarks != ''"
          matTooltip="{{ infoRemarks }}"
          class="fa-solid fa-circle-info"
        ></i>
        <input
          class="form-control"
          name="remarks"
          [(ngModel)]="item.remarks"
          id="remarks"
          size="40"
          [disabled]="item?.readonly"
        />
      </div>
    </div>
    <div class="row" *ngIf="item?.showFurtherLearnings">
      <div class="col-md-12">
        <label for="furtherLearnings"
          >Weiterf&uuml;hrende Lernfelder / Empfehlungen f&uuml;r das
          n&auml;chste Praktikum: &nbsp;</label
        >
        <i
          *ngIf="infoFurtherLerning != null && infoFurtherLerning != ''"
          matTooltip="{{ infoFurtherLerning }}"
          class="fa-solid fa-circle-info"
        ></i>
        <input
          class="form-control"
          name="furtherLearnings"
          [(ngModel)]="item.furtherLearnings"
          id="furtherLearnings"
          size="40"
          [disabled]="item?.readonly"
        />
      </div>
    </div>
  </div>

  <div *ngIf="deptString === 'IHB'">
    <div class="col-md-6" *ngIf="item?.showOverallGrade123">
      <label for="grade"
        >Gesamtnote:<span
          class="red"
          *ngIf="
            item?.showOverallGrade123 &&
            (item.grade === null || item.grade == '' || item.grade == '0')
          "
        >
          * </span
        >&nbsp;<span *ngIf="!isDiskreditierungRight" style="color: red"
          >Schritt zwischen Noten muss 0.5 sein</span
        ></label
      >
      <input
        class="form-control"
        name="grade"
        [(ngModel)]="item.grade"
        id="grade"
        size="40"
        (change)="setGradeDateAndDiscretization()"
        [disabled]="itemReadonlyForIHG"
      />
    </div>

    <div class="row" *ngIf="item?.showOverallGrade456">
      <label for="passedStates"
        >Status:<span
          class="red"
          *ngIf="
            item?.showOverallGrade456 &&
            (item.zmcGradeId === null || item.zmcGradeId === '')
          "
        >
          *
        </span></label
      >
      <mat-radio-group
        name="passedStates"
        [(ngModel)]="item.zmcGradeId"
        [disabled]="itemReadonlyForIHG"
      >
        <div class="col-sm">
          <mat-radio-button
            [checked]="passedState.dataSourceObjectId === item.zmcGradeId"
            *ngFor="let passedState of zmcNotenskala"
            [value]="passedState.dataSourceObjectId"
          >
            {{ passedState.name }}&nbsp; &nbsp;
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="col-md-6" *ngIf="item?.showAbsences">
      <label for="absenceInDays"
        >Absenzen (Anzahl Tage)<span
          class="red"
          *ngIf="item.absences === null || item.absences == ''"
        >
          *
        </span></label
      >
      <input
        class="form-control"
        name="absenceInDays"
        [(ngModel)]="item.absences"
        id="absenceInDays"
        [disabled]="itemReadonlyForIHG"
      />
    </div>

    <div
      class="col-md-6"
      *ngIf="item?.showReasonForAbsences && !isPm1OrPm2 && !isPm3"
    >
      <label for="reasonForAbsences"
        >Grund für Absenz<span
          class="red"
          *ngIf="
            item.reasonForAbsences === null || item.reasonForAbsences == ''
          "
        >
          *
        </span></label
      >
      <input
        class="form-control"
        name="reasonForAbsences"
        [(ngModel)]="item.reasonForAbsences"
        id="reasonForAbsences"
        [disabled]="itemReadonlyForIHG"
      />
    </div>

    <div class="row" *ngIf="item?.showOverallAssessment">
      <div class="col-md-12">
        <label
          ><span *ngIf="item?.showOverallGrade123"
            >Modulabschlussbeurteilung (Zusammenfassung der
            Gesamtleistung)</span
          ><span *ngIf="item?.showOverallGrade456"
            >Abschlussbeurteilung (Zusammenfassung der Gesamtleitung)</span
          ><span
            class="red"
            *ngIf="
              item.overallAssessment === null || item.overallAssessment === ''
            "
          >
            *
          </span></label
        >
        <p-editor
          [style]="{ height: 'auto' }"
          id="overallAssessment"
          name="overallAssessment"
          [(ngModel)]="this.item.overallAssessment"
          [readonly]="itemReadonlyForIHG"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>

    <div class="row" *ngIf="item?.showDevelopmentMeasures">
      <div class="col-md-12">
        <label
          ><span *ngIf="item?.showOverallGrade123"
            >Entwicklungs- und Förderungsmassnahmen für künftige
            Praxismodule</span
          ><span *ngIf="item?.showOverallGrade456"
            >Ziele für die Weiterentwicklung</span
          ><span
            class="red"
            *ngIf="
              item.developmentMeasures === null ||
              item.developmentMeasures === ''
            "
          >
            *
          </span></label
        >
        <p-editor
          [style]="{ height: 'auto' }"
          id="developmentMeasures"
          name="developmentMeasures"
          [(ngModel)]="this.item.developmentMeasures"
          [readonly]="itemReadonlyForIHG"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>

    <div class="row" *ngIf="item?.showAssessmentFormReceiptIs">
      <div class="col-md-12">
        <label for="assessmentFormReceiptIs"
          >Beurteilungsbogen eingereicht (Datum)</label
        ><input
          type="date"
          class="form-control"
          name="assessmentFormReceiptIs"
          [(ngModel)]="item.assessmentFormReceiptIs"
          id="assessmentFormReceiptIs"
          [disabled]="true"
        />
      </div>
    </div>
  </div>

  <div style="padding-top: 10px">
    <button class="btn btn-secondary" (click)="cancelEdit()">Abbrechen</button>
    <button
      class="btn btn-primary"
      [disabled]="
        (item?.showNetWorkingHours && item?.netWorkingHours === null) ||
        !isDiskreditierungRight
      "
      (click)="save()"
    >
      Speichern und zur&uuml;ck
    </button>
  </div>
</div>
