import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { ErrorHandlerComponent } from './error-handler.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule],
  declarations: [ErrorHandlerComponent],
  providers: [],
  exports: [ErrorHandlerComponent],
})
export class ErrorHandlerModule {}
