import { NgModule } from '@angular/core';
import { TrainerSelectDialogComponent } from './trainer-select-dialog.component';
import { ProcessingModule } from '../processing/processing.module';
import { SharedModule } from '../../shared/shared.module';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [ProcessingModule, SharedModule, TableModule],
  declarations: [TrainerSelectDialogComponent],
  providers: [],
  exports: [TrainerSelectDialogComponent],
})
export class TrainerSelectDialogModule {}
