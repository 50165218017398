import { Injectable } from '@angular/core';

// classes
import { InternshipDetail } from '../models/internship-detail';
import { InternshipGridInfo } from '../models/internship-grid-info';
import { GridRequest } from '../models/grid-request';

// factories
import { UserGroup } from '../models/usergroup';
import { catchError, lastValueFrom, map, throwError } from 'rxjs';
import { getErrorMessage } from '../../layout/shared/shared.tools';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class InternshipService {
  constructor(private http: HttpClient) {}

  internshipGridInfo: InternshipGridInfo;
  internship: InternshipDetail;
  internshipListUrl = environment.apiUrl + '/api/pb/registration/GetListAsync';
  internshipsDetailUrl = environment.apiUrl + '/api/pb/registration/GetAsync';
  internshipsDetailByGradeIdUrl =
    environment.apiUrl + '/api/pb/registration/GetAsyncByGradeId';
  updateInternshipsDetailUrl = environment.apiUrl + '/api/pb/registration';
  internshipsReportUrl =
    environment.apiUrl + '/api/pb/report/GetRegistrationReport';
  getDeptStringUrl = environment.apiUrl + '/api/pb/registration/GetDeptString';
  gradeUserGroup: string =
    environment.apiUrl + '/api/pb/registration/UserHasStudentRole/';

  public getInternships(gridRequest: GridRequest): Promise<InternshipGridInfo> {
    var data = JSON.stringify(gridRequest);
    let config: Object = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    return lastValueFrom(
      this.http
        .post<InternshipGridInfo>(this.internshipListUrl, data, config)
        .pipe(
          map((result: any) => {
            this.internshipGridInfo = new InternshipGridInfo();
            this.internshipGridInfo.count = result.count;
            this.internshipGridInfo.internships = result.registrations;

            return this.internshipGridInfo;
          })
        )
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public UserHasStudentRole(id: number): Promise<UserGroup> {
    let url: string = this.gradeUserGroup + id;

    return lastValueFrom(
      this.http
        .get<UserGroup>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public getInternshipDetail(id: number): Promise<InternshipDetail> {
    let url: string = `${this.internshipsDetailUrl}/${id}`;
    return lastValueFrom(
      this.http
        .get<InternshipDetail>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public getInternshipDetailByGradeId(
    gradeId: number
  ): Promise<InternshipDetail> {
    let url: string = `${this.internshipsDetailByGradeIdUrl}/${gradeId}`;
    return lastValueFrom(
      this.http
        .get<InternshipDetail>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public updateInternshipDetail(item: InternshipDetail): Promise<any> {
    let config: Object = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return lastValueFrom(
      this.http
        .put(
          `${this.updateInternshipsDetailUrl}/put/${item.id}`,
          JSON.stringify(item),
          config
        )
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public downloadRegistrationReport(registrationId: number): any {
    let url: string = `${this.internshipsReportUrl}/${registrationId}`;
    let config: Object = {
      responseType: 'blob',
    };
    return this.http.get(url, config);
  }

  downloadBlob(blob: Blob, filename: string, fileending: string): void {
    var fileURL = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = fileURL;
    a.target = '_blank';
    a.download = filename + fileending;
    document.body.appendChild(a);
    a.click();
  }

  public getDeptString(): Promise<string> {
    let url: string = `${this.getDeptStringUrl}`;

    return lastValueFrom(
      this.http
        .get<any>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public resetCache() {
    this.internshipGridInfo = null;
    this.internship = null;
  }
}
