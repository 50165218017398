import { NgModule } from '@angular/core';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { InternshipDetailFormModule } from './domain/internship-detail-form/internship-detail-form.module';
import { InternshipOverviewModule } from './domain/internship-overview/internship-overview.module';
import { GradeDetailFormModule } from './domain/grade-detail-form/grade-detail-form.module';

import { InternshipService } from './domain/services/internship.service';
import { WorkflowStepService } from './domain/services/workflow-step.service';
import { HeaderModule } from './layout/header/header.module';
import { GradeService } from './domain/services/grade.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { InternshipListModule } from './domain/internship-list/internship-list.module';
import { NotAllowedComponent } from './layout/not-allowed/not-allowed.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { TrainerSelectDialogModule } from './domain/trainer-select-dialog/trainer-select-dialog.module';

// Azure AD
// Import MSAL and MSAL browser libraries.
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { environment } from '../environments/environment';
import { LoginComponent } from './layout/login/login.component';
// Import the Azure AD B2C configuration

// For Edu ID Login
import { OAuthModule } from 'angular-oauth2-oidc';
import { MsalSkipInterceptor } from './auth/msal-skip.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';

@NgModule({
  imports: [
    SharedModule,
    AppRoutingModule,
    InternshipListModule,
    InternshipOverviewModule,
    InternshipDetailFormModule,
    TrainerSelectDialogModule,
    HeaderModule,
    GradeDetailFormModule,
    HttpClientModule,
    TableModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,

    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [
            ...protectedResources.praxistoolApi.scopes,
            ...loginRequest.scopes,
          ],
        },
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(
          protectedResources.praxistoolApi.endpoints.map((e) => [
            e,
            protectedResources.praxistoolApi.scopes,
          ])
        ),
      }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // For Edu ID Login
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: protectedResources.praxistoolApi.endpoints,
        sendAccessToken: true,
      },
    }),
  ],
  declarations: [AppComponent, NotAllowedComponent, LoginComponent],
  providers: [
    InternshipService,
    WorkflowStepService,
    GradeService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalSkipInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
