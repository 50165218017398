import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { GradeDetailFormComponent } from './grade-detail-form.component';
import { EvaluationComponent } from '../evaluation/evaluation.component';
import { GradeDetailFormRoutingModule } from './grade-detail-form.routing';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { ProcessingModule } from '../processing/processing.module';
import { InternshipInfoModule } from '../internship-info/internship-info.module';
import { EditorModule } from 'primeng/editor';

@NgModule({
  imports: [
    SharedModule,
    GradeDetailFormRoutingModule,
    ReactiveFormsModule,
    ErrorHandlerModule,
    ProcessingModule,
    InternshipInfoModule,
    EditorModule,
  ],
  declarations: [GradeDetailFormComponent, EvaluationComponent],
  providers: [],
  exports: [GradeDetailFormComponent],
})
export class GradeDetailFormModule {}
