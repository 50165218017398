import { Injectable } from '@angular/core';

// classes
import { GradeDetail } from '../models/grade-detail';
import { UserGroup } from '../models/usergroup';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { getErrorMessage } from '../../layout/shared/shared.tools';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class GradeService {
  constructor(private http: HttpClient) {}

  private putGradeDetailUrlBase: string =
    environment.apiUrl + '/api/pb/grade/put/';
  private gradeDetailUrlBase: string =
    environment.apiUrl + '/api/pb/grade/GetAsync/';
  private gradeUserGroup: string =
    environment.apiUrl + '/api/pb/grade/UserHasStudentRole/';
  private getGradeGradingScaleElement: string =
    environment.apiUrl + '/api/pb/grade/GetGradingScaleElement/';

  public getGradeDetail(id: number): Promise<GradeDetail> {
    let url: string = this.gradeDetailUrlBase + id;

    return lastValueFrom(
      this.http
        .get<GradeDetail>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public UserHasStudentRole(id: number): Promise<UserGroup> {
    let url: string = this.gradeUserGroup + id;
    return lastValueFrom(
      this.http
        .get<UserGroup>(url)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public updateGradeDetail(item: GradeDetail): Promise<any> {
    let config: Object = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return lastValueFrom(
      this.http
        .put(this.putGradeDetailUrlBase + item.id, JSON.stringify(item), config)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }

  public getGradingScaleElement(id: string): Promise<any> {
    let config: Object = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return lastValueFrom(
      this.http
        .get<any>(this.getGradeGradingScaleElement + id, config)
        .pipe(
          catchError((error) =>
            throwError(() => new Error(getErrorMessage(error)))
          )
        )
    );
  }
}
