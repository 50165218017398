import { FilterMetadata } from 'primeng/api';

export class GridRequest {
  filters?: {
    [s: string]: FilterMetadata | FilterMetadata[] | undefined;
  };
  offset: number;
  pageSize: number;
  sortfield: string | null;
  sortAsc: boolean;
  withArchive: boolean;
  filterOrSortChanged: boolean;
}
