import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InternshipDetailFormComponent } from './internship-detail-form.component';
import { GlobalSettings } from '../../shared/global-settings';

export const routes: Routes = [
  {
    path: GlobalSettings.routes.internshipDetail,
    component: InternshipDetailFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class InernshipDetailFormRoutingModule {}
