<select
  [id]="id"
  [attr.name]="id"
  [(ngModel)]="selectedValue"
  [disabled]="disabled"
  class="form-control"
  (change)="selected()"
>
  <option
    *ngFor="let value of gradeValues"
    [ngValue]="value"
    [selected]="grade?.valueDecimal == value"
  >
    {{ value }}
  </option>
</select>
