import { HttpErrorResponse } from '@angular/common/http';
import { InternshipDetail } from '../../domain/models/internship-detail';

//import { environment } from '../../environments/environment';

// ------------------------------
// if an error is thrown (mostly when thrown by the web API), use this method to extract the message.
// ------------------------------
export const getErrorMessage = (val: any, fallback?: string): string => {
  let message: string;

  if (val == null || val == undefined) {
    message = fallback;
  } else if (val instanceof HttpErrorResponse) {
    let blobError = val.headers.get('BlobErrorMessage');
    if (blobError) {
      message = decodeURIComponent(blobError);
    } else {
      message = getErrorMessage(val.error, fallback);
    }
  } else if (val.modelState != undefined) {
    // Object.values is unsupported in IE11 - https://stackoverflow.com/questions/43254982/object-doesnt-support-property-or-method-values/43255030
    var values = Object.keys(val.modelState).map(function (i) {
      return val.modelState[i];
    });
    message = values.join('\n');
  } else if (val.error_description != undefined) {
    message = val.error_description;
  } else if (val.message != undefined) {
    message = val.message;
  } else if (val instanceof String || typeof val === 'string') {
    message = val.toString();
  }

  if (message == undefined) {
    message = fallback || 'Unknown error format.';
  }

  // logging of errors / exceptions
  console.log(val);
  //logDebug(val);

  return message;
};

// ------------------------------
// simple pendant of the String.Format() .NET function
// ------------------------------
export const stringFormat = (input: string, ...params: any[]): string => {
  let result = input;

  for (let i = 0; i < params.length; i++) {
    let re = new RegExp(`\\{${i}\\}`, 'g');
    result = result.replace(re, params[i]);
  }

  return result;
};

// ------------------------------
// static CH date formatting function
// ------------------------------
export const dateFormat = (input: Date): string => {
  var inputDate = new Date(input);
  var day = inputDate.getDate().toString().padStart(2, '0');
  var month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  var year = inputDate.getFullYear().toString().padStart(4, '0');
  var result = `${day}.${month}.${year}`;
  return result;
};

// ------------------------------
// simple debug log function which only logs debug messages when in dev environment
// ------------------------------
/* export const logDebug = (message: string): void => {
  if (!environment.production) {
    console.debug(message);
  }
}; */

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const registrationReportName = (item: InternshipDetail): string => {
  return (
    item.student?.lastName +
    '-' +
    item.student?.firstName +
    '_' +
    item?.internshipType +
    '_' +
    item.practiceOrganization?.name
  );
};
