import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InternshipOverviewComponent } from './internship-overview.component';
import { GlobalSettings } from '../../shared/global-settings';

const overviewRoutes: Routes = [
  {
    path: GlobalSettings.routes.internshipOverview,
    component: InternshipOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(overviewRoutes)],
  exports: [],
  providers: [],
})
export class InernshipOverviewRoutingModule {}
