import { Component, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {
  authCodeFlowConfig,
  azureAD,
  loginRequest,
  msalConfig,
  switchEduID,
} from '../../auth-config';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  dataSource: Claim[] = [];
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  departmentId: BehaviorSubject<string>;
  username: string;
  showMentor = false;
  showInternshipperiods = true;
  showSubjectareas = true;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private oauthService: OAuthService,
    private authStorage: OAuthStorage,
    public dialog: MatDialog
  ) {
    // Automatically load user profile
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((_) => {
        this.oauthService.loadUserProfile();
        this.switchEduIDAcountHandling();
      });

    this.oauthService.events.subscribe((e) => console.log(e));

    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_expires'))
      .subscribe((_) => {
        this.oauthService.refreshToken();
      });
  }

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {});

    this.msalService.instance.initialize().then(() => {
      this.msalService.instance
        .acquireTokenSilent(loginRequest)
        .then(() => this.doAccountHandling())
        .catch((err) => {
          //console.error(err);
          this.router.navigate(['/']);
        });
    });

    if (this.oauthService.hasValidAccessToken()) {
      this.switchEduIDAcountHandling();
      return;
    }
  }

  async azureLogin(): Promise<void> {
    //console.log("azure login");
    await this.msalService.instance.initialize();
    this.msalService.instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = this.msalService.instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            this.msalService.loginPopup().subscribe(() => {
              this.doAccountHandling();
            });
          }
        }
      })
      .catch((err) => {
        //console.error(err);
      });
  }

  eduIdLogin(): void {
    this.oauthService.events.subscribe((e) => console.log(e));

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService
      .loadDiscoveryDocument()

      .then(() => this.oauthService.tryLoginCodeFlow())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          //this.oauthService.setupAutomaticSilentRefresh();
          this.switchEduIDAcountHandlingWithNavigate();
          return Promise.resolve();
        }
        return new Promise((resolve) => {
          this.oauthService
            .initLoginFlowInPopup({ height: 550, width: 500 })
            .then((result) => {
              if (this.oauthService.hasValidAccessToken()) {
                this.switchEduIDAcountHandlingWithNavigate();
              }
            });
          this.oauthService.loadDiscoveryDocumentAndTryLogin();
        });
      });
  }

  switchEduIDAcountHandling() {
    this.loginDisplay = true;
    this.authStorage.setItem('login_method', switchEduID);
  }

  switchEduIDAcountHandlingWithNavigate() {
    this.loginDisplay = true;
    this.authStorage.setItem('login_method', switchEduID);
    this.router.navigate(['/internships']);
  }

  logout() {
    //this.authStorage.setItem('login_method', 'logged_out');
    //this.oauthService.revokeTokenAndLogout();
    if (this.authStorage.getItem('login_method') == switchEduID) {
      try {
        this.oauthService.logOut(true);
      } catch (error) {
        console.error(error);
        // Expected output: ReferenceError: nonExistentFunction is not defined
        // (Note: the exact output may be browser-dependent)
        window.location.href = authCodeFlowConfig.redirectUri;
      }
      window.location.href = authCodeFlowConfig.redirectUri;

      //this.oauthService.revokeTokenAndLogout();
    }

    if (this.authStorage.getItem('login_method') == azureAD) {
      this.msalService.instance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            const accounts = this.msalService.instance.getAllAccounts();
            if (accounts.length > 0) {
              this.msalService.logoutRedirect({
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
              });
              location.reload();
            }
          }
        });
    }
  }

  doAccountHandling() {
    let accounts = this.msalService.instance.getAllAccounts();
    this.setLoginDisplay(accounts);
    this.checkAndSetActiveAccount(accounts);
    this.authStorage.setItem('login_method', azureAD);
    if (accounts.length > 0) {
      this.router.navigate(['/internships']);
    }
  }

  setLoginDisplay(accounts: AccountInfo[]) {
    this.loginDisplay = accounts.length > 0;
  }

  checkAndSetActiveAccount(accounts: AccountInfo[]) {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && accounts.length > 0) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    /*console.log(
      'get active account',
      this.msalService.instance.getActiveAccount()
    );*/

    this.username = this.msalService.instance.getActiveAccount().username;
  }

  getClaims() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount?.idTokenClaims) return;
    let claims: any = activeAccount?.idTokenClaims;
    let list: Claim[] = new Array<Claim>();
    Object.keys(claims).forEach(function (k, v) {
      let c = new Claim();
      c.id = v;
      c.claim = k;
      c.value = claims ? claims[k] : null;
      list.push(c);
      if (!environment.production)
        console.log(`ID: ${c.id}; Claim  ${c.claim}; Value ${c.value}`);
    });
    this.dataSource = list;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  openInfoDialog() {
    this.dialog.open(DialogLoginInfo);
  }
}

export class Claim {
  id: number;
  claim: string;
  value: string;
}

@Component({
  selector: 'dialog-login-info',
  templateUrl: 'dialog-login-info.html',
})
export class DialogLoginInfo {
  constructor() {}
}
