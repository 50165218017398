import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DialogLoginInfo, HeaderComponent } from './header.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, RouterModule, MatTooltipModule, MatDialogModule],
  declarations: [HeaderComponent, DialogLoginInfo],
  exports: [HeaderComponent, DialogLoginInfo],
  providers: [],
})
export class HeaderModule {}
