<internship-info [item]="parentInternship"></internship-info>
<h1>{{ evaluationGroup?.name }}</h1>
<pre>{{ evaluationGroup?.description }}</pre>
<h2 *ngIf="deptString !== 'IHB'">{{ item?.name }}</h2>
<section *ngIf="item?.description" [innerHTML]="item?.description"></section>

<app-error-handler [error]="error"></app-error-handler>
<app-processing [isUpdating]="isUpdating()"></app-processing>
<div
  *ngIf="!fatalError && gradeDetailForm != null && item != null"
  class="container-fluid {{ isUpdating() ? 'loading' : '' }}"
>
  <form class="ui large form" [formGroup]="gradeDetailForm">
    <div class="row" *ngIf="item?.showTargetAgreement">
      <div class="form-group col-md-8">
        <label for="objectives">Vereinbarte Ziele</label>
        <p-editor
          [style]="{ height: 'auto' }"
          name="objectives"
          [(ngModel)]="item.objectives"
          id="objectives"
          [readonly]="!item?.canInsertTargetAgreement || item?.readonly"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
      <div class="form-group col-md-4">
        <label for="objectiveDeadlines">Fristen</label>
        <p-editor
          [style]="{ height: 'auto' }"
          name="objectiveDeadlines"
          [(ngModel)]="item.objectiveDeadlines"
          id="objectiveDeadlines"
          [readonly]="!item?.canInsertTargetAgreement || item?.readonly"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>
    <div class="row" *ngIf="item?.showIndicator">
      <div class="form-group col-md-8">
        <label for="indicators">Indikatoren</label>
        <p-editor
          [style]="{ height: 'auto' }"
          name="indicators"
          [(ngModel)]="item.indicators"
          id="indicators"
          [readonly]="!item?.canInsertTargetAgreement || item?.readonly"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
      <div class="form-group col-md-4">
        <label for="indicatorDeadlines">Fristen</label>
        <p-editor
          [style]="{ height: 'auto' }"
          name="indicatorDeadlines"
          [(ngModel)]="item.indicatorDeadlines"
          id="indicatorDeadlines"
          [readonly]="!item?.canInsertTargetAgreement"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>
    <div class="row" *ngIf="item?.showExemplarySituation">
      <div class="form-group col-md-12">
        <label for="exemplarySituation">Exemplarische Situation</label>
        <input
          class="form-control"
          name="exemplarySituation"
          [(ngModel)]="item.exemplarySituation"
          id="exemplarySituation"
          size="40"
          [disabled]="item?.readonly"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
    <div class="row" *ngIf="item?.showTargetAgreement">
      <div class="form-group col-md-12">
        <label for="valueFreeText">Einsch&auml;tzung</label>
        <p-editor
          [style]="{ height: 'auto' }"
          name="valueFreeText"
          [(ngModel)]="item.valueFreeText"
          id="valueFreeText"
          size="40"
          [readonly]="!item?.canEvaluate || item?.readonly || isStudent"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6" *ngIf="item?.showSAndErgoGradeField">
        <span class="no-padding-left"
          ><label for="evaluation">Endbeurteilung</label></span
        ><span class="float-right"
          ><label>{{ item?.evaluationDate | date : "dd.MM.yyyy" }}</label></span
        >
        <app-evaluation
          id="evaluation"
          [grade]="item"
          [selectedValue]="item?.valueDecimal"
          (onSelect)="setEvaluation($event)"
          [disabled]="!item?.canEvaluate || item?.readonly || isStudent"
        ></app-evaluation>
      </div>
      <div class="form-group col-md-6" *ngIf="item?.showInterimAssessments">
        <span class="no-padding-left"
          ><label for="interimAssessment1">Zwischenbeurteilung 1</label> </span
        ><span class="float-right">
          <label>{{
            item?.evaluationDateInterimAssessment1 | date : "dd.MM.yyyy"
          }}</label>
        </span>
        <app-evaluation
          id="interimAssessment1"
          [grade]="item"
          [selectedValue]="item?.interimAssessment1"
          (onSelect)="setInterimAssessment1($event)"
          [disabled]="
            !item?.showInterimAssessments ||
            item?.readonly ||
            item?.interimAssessment1ReadOnly
          "
        ></app-evaluation>
      </div>
    </div>
    <div class="row" *ngIf="item?.showInterimAssessments">
      <div class="form-group col-md-6">
        <span>
          <label for="interimAssessment2">Zwischenbeurteilung 2</label> </span
        ><span class="float-right"
          ><label>{{
            item?.evaluationDateInterimAssessment2 | date : "dd.MM.yyyy"
          }}</label>
        </span>
        <app-evaluation
          id="interimAssessment2"
          [grade]="item"
          [selectedValue]="item?.interimAssessment2"
          (onSelect)="setInterimAssessment2($event)"
          [disabled]="!item?.showInterimAssessments || item?.readonly"
        ></app-evaluation>
      </div>
    </div>

    <div class="row" *ngIf="item?.showIhbGradeField">
      <div class="form-group col-md-12">
        <label for="valueFreeText"
          >Zusammenfassung der erbrachten Leistung entsprechend Niveau/Stufe und
          Beurteilungsskala</label
        >
        <p-editor
          [style]="{ height: 'auto' }"
          name="valueFreeText"
          [(ngModel)]="item.valueFreeText"
          id="valueFreeText"
          size="40"
          [readonly]="!item?.canEvaluate || item?.readonly || isStudent"
          [ngModelOptions]="{ standalone: true }"
          spellcheck="false"
          ><ng-template pTemplate="header">
            <span class="ql-format-group">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span> </ng-template
        ></p-editor>
      </div>
    </div>

    <div style="padding-top: 10px" *ngIf="!isUpdating()">
      <button
        type="submit"
        class="btn btn-primary"
        (click)="previous()"
        title="{{ previousGrade?.name }}"
      >
        <<
      </button>
      <button type="button" class="btn btn-secondary" (click)="cancelEdit()">
        Zur&uuml;ck
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="save()"
        [disabled]="gradeDetailForm?.invalid"
      >
        Speichern
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="next()"
        title="{{ nextGrade?.name }}"
      >
        >>
      </button>
    </div>
  </form>
</div>
