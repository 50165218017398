<div class="row">
  <div class="col">
    <h2 class="mb-3">Praxisausbildende</h2>
    <p-table
      #dt
      [lazy]="true"
      (onLazyLoad)="loadTrainers($event)"
      [totalRecords]="totalRecords"
      [rows]="pageSize"
      [value]="trainers"
      [paginator]="true"
      [scrollable]="true"
      (onFilter)="filter($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width: 220px">
            <div pSortableColumn="name">
              Name
              <p-sortIcon field="name"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="name"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="organization">
              Organisation
              <p-sortIcon field="organization"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="organization"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="addressText">
              Adresse
              <p-sortIcon field="addressText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="addressText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="cityText">
              Ort
              <p-sortIcon field="cityText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="cityText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="emailText">
              Email
              <p-sortIcon field="emailText"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="emailText"
              [showMenu]="false"
              matchMode="contains"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 220px">
            <div pSortableColumn="telefon">
              Telefon
              <p-sortIcon field="telefon"></p-sortIcon>
            </div>
            <p-columnFilter
              type="text"
              field="telefon"
              matchMode="contains"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            ></p-columnFilter>
          </th>
          <th style="min-width: 60px" alignFrozen="right" pFrozenColumn>
            &nbsp;
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-trainer let-rowIndex="rowIndex">
        <tr [pSelectableRow]="trainer" [pSelectableRowIndex]="rowIndex">
          <td style="min-width: 220px">{{ trainer.name }}</td>
          <td style="min-width: 220px">{{ trainer.organization }}</td>
          <td style="min-width: 220px">{{ trainer.addressText }}</td>
          <td style="min-width: 220px">{{ trainer.cityText }}</td>
          <td style="min-width: 220px">{{ trainer.emailText }}</td>
          <td style="min-width: 220px">{{ trainer.telefon }}</td>
          <td
            class="functionicons"
            style="min-width: 60px"
            alignFrozen="right"
            pFrozenColumn
          >
            <button
              class="btn"
              (click)="selectTrainer(trainer)"
              ngbTooltip="Auswählen"
            >
              <i class="fas fa-solid fa-plus bluecolor"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-processing [isUpdating]="isUpdating"></app-processing>
